<template>
    <div class="cardbox">
        <div class="cardboxtitle">
            <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6H8C6.89543 6 6 6.89543 6 8V18C6 19.1046 6.89543 20 8 20H18C19.1046 20 20 19.1046 20 18V8C20 6.89543 19.1046 6 18 6Z" fill="none" stroke="#ffffff" stroke-width="4" stroke-linejoin="round"/><path d="M18 28H8C6.89543 28 6 28.8954 6 30V40C6 41.1046 6.89543 42 8 42H18C19.1046 42 20 41.1046 20 40V30C20 28.8954 19.1046 28 18 28Z" fill="none" stroke="#ffffff" stroke-width="4" stroke-linejoin="round"/><path d="M40 6H30C28.8954 6 28 6.89543 28 8V18C28 19.1046 28.8954 20 30 20H40C41.1046 20 42 19.1046 42 18V8C42 6.89543 41.1046 6 40 6Z" fill="none" stroke="#ffffff" stroke-width="4" stroke-linejoin="round"/><path d="M40 28H30C28.8954 28 28 28.8954 28 30V40C28 41.1046 28.8954 42 30 42H40C41.1046 42 42 41.1046 42 40V30C42 28.8954 41.1046 28 40 28Z" fill="none" stroke="#ffffff" stroke-width="4" stroke-linejoin="round"/></svg>            
            <h3>一些乱七八糟的东西</h3>
        </div>
        <div class="cardgroup">
            <div v-for="card in cards">
                <div class="carditem" v-if="card.type!=='app'"  :key="card.name" @click="openurl(card.link)">
                    <img class="cardicon" :src="getIconPath(card.icon)" alt="">
                    <h4>{{ card.name }}</h4>
                </div>
            </div>
        </div>
    </div>
    <div class="cardbox">
        <div class="cardboxtitle">
            <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6H8C6.89543 6 6 6.89543 6 8V18C6 19.1046 6.89543 20 8 20H18C19.1046 20 20 19.1046 20 18V8C20 6.89543 19.1046 6 18 6Z" fill="none" stroke="#ffffff" stroke-width="4" stroke-linejoin="round"/><path d="M18 28H8C6.89543 28 6 28.8954 6 30V40C6 41.1046 6.89543 42 8 42H18C19.1046 42 20 41.1046 20 40V30C20 28.8954 19.1046 28 18 28Z" fill="none" stroke="#ffffff" stroke-width="4" stroke-linejoin="round"/><path d="M40 6H30C28.8954 6 28 6.89543 28 8V18C28 19.1046 28.8954 20 30 20H40C41.1046 20 42 19.1046 42 18V8C42 6.89543 41.1046 6 40 6Z" fill="none" stroke="#ffffff" stroke-width="4" stroke-linejoin="round"/><path d="M40 28H30C28.8954 28 28 28.8954 28 30V40C28 41.1046 28.8954 42 30 42H40C41.1046 42 42 41.1046 42 40V30C42 28.8954 41.1046 28 40 28Z" fill="none" stroke="#ffffff" stroke-width="4" stroke-linejoin="round"/></svg>            
            <h3>开发的App「AppStore已上架」</h3>
        </div>
        <div class="cardgroup">
            <div v-for="card in cards">
                <div class="carditem" v-if="card.type=='app'"  :key="card.name" @click="openurl(card.link)">
                    <img class="cardicon" :src="getIconPath(card.icon)" alt="">
                    <h4>{{ card.name }}</h4>
                </div>
            </div>
        </div>
    </div>
</template>  
<script>
import something from '@/assets/something.json'

export default {
    data() {
        return {
            cards: something[0]
        }
    },
    methods: {
        openurl(url) {
            window.open(url)
        },
        getIconPath(icon) {
            return require(`@/assets/icon/${icon}`)
        }
    }
}
</script>
<style scoped>
.cardbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    padding: 10px;
}
.cardboxtitle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
}
.cardicon {
    width: 30px;
    height: 30px;
    border-radius: 10px;
}
.cardgroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}
.carditem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    backdrop-filter: blur(10px);
    color: white;
    padding: 20px;
    transition: 0.5s;
    margin: 10px;
}
.carditem:hover {
    scale: 1.05;
}
</style>