<template>
  <div class="main">
    <Background />
    <Info />
    <Cards />
    <Footer />
  </div>
</template>

<script>
import Background from '@/components/Background.vue'
import Info from '@/components/Info.vue'
import Footer from '@/components/Footer.vue'
import Cards from '@/components/Cards.vue'

export default {
  name: 'HomeView',
  components: {
    Background,
    Info,
    Footer,
    Cards
  }
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}
.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
</style>