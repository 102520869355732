<template>
    <a class="infotitle">Hello I'm Damien</a>
    <div class="infobox">
        <div class="textbox">
            <a>一名在21世纪苟活着的大学生</a>
        </div>
        <div class="badagebox">
            <a class="badage">00后</a>
            <a class="badage">烦恼多</a>
            <a class="badage">快乐少</a>
            <a class="badage">理科生</a>
        </div>
    </div>
</template>
<style scoped>
.infotitle {
    font-size: 50px;
    font-weight: bold;
    color: white;
    padding: 10px;
}
.badagebox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5px;
}
.badage {
    border-radius: 10px;
    backdrop-filter: blur(10px);
    padding: 5px;
    color: white;
}
.textbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    backdrop-filter: blur(10px);
    color: white;
    padding: 20px;
    transition: 0.5s;
}
.textbox:hover {
    scale: 1.05;
}

</style>