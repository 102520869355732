<template>
    <div class="bgbox">
        <!-- <a href="" class="imgbadage">图片来自于MacOS 10.12 Sierra</a> -->
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}
.bgbox{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
    background-image: url("@/assets/bg.webp");
    background-size: cover;
    background-position: center;
}
.imgbadage {
    position: absolute;
    bottom: 5%;
    right: 10%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    padding: 5px;
    color: white;
    text-decoration: none;
}
</style>
  