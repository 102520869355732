<template>
    <div class="footerbox">
        <a class="footertext">Copyright©️2022 dominik</a>
        <a class="footertext" href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备2020019489号</a>
    </div>
</template>
<style scoped>
a{
    color: white;
    text-decoration: none;
}
.footerbox{
    position: relative;
    font-size: small;
    display: flex;
    flex-direction: column;
    bottom: 0;
    text-align: center;
    width: 100%;
    border-radius: 20px;
    backdrop-filter: blur(10px);
}
</style>